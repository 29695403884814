// ==============================
// * PAGE DESIGNER: CATEGORY ITEM
//
// ? This file gathers all the custom category item CSS.
// ==============================

@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.category-item {
    padding: 45px 70px;
    background-color: $gray-2;
    border-radius: 40px;

    &__image {
        img {
            max-height: 275px;
            height: auto;
            max-width: 100%;
            width: auto !important; //override slick style
            margin: auto;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 60px;
        margin-top: 40px;
        text-align: center;

        i {
            display: none;
        }
    }
}
